import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCards(_, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/aboutWhoList`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCard(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/aboutWhoById', { params: { id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCard(_, card) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/aboutWhoCreate', card)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCard(_, card) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/aboutWhoUpdate', card)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCard(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/aboutWhoDelete', { id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    swapPosition(_, { oldPosition, newPosition}) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/movePosition/aboutWho', { old: oldPosition, new: newPosition })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
